const defaultStyle = {
  transition: `opacity 400ms ease-in-out`,
  opacity: 0,
}

const transitionStyles = {
  entered: { opacity: 1 },
}

export { defaultStyle, transitionStyles }
